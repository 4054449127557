@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@font-face {
  font-family: "Neusa";
  src: url("./static/fonts/Neusa-Regular.otf");
  font-weight: regular;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7FCFF;
  color: #211f22;
  font-size: 13px;
}
input, textarea {
  font-family: 'Poppins', sans-serif;
  width: calc(100% - 10px);
  min-height: 30px;
  letter-spacing: .5px;
  border: 0;
  background-color: #F7FCFF !important;
  border-bottom: 1px solid #f0f0f0;
}


input:focus, textarea:focus {
  outline: none;
  border-color: #021217;
}

input:valid, textarea:valid {
  border-color: #036d28;
}

input:invalid, textarea:invalid {
  border-color: red;
}


textarea {
  min-height: 120px;
}

small{
  opacity: .666;
  font-size: .5em;
}

.grow-wrap {
  display: grid;
}
.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}

label {
  display: block;
  margin-top: -10px;
}