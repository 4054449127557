@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
@font-face {
  font-family: "Neusa";
  src: url(/static/media/Neusa-Regular.6ab411b2.otf);
  font-weight: regular;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7FCFF;
  color: #211f22;
  font-size: 13px;
}
input, textarea {
  font-family: 'Poppins', sans-serif;
  width: calc(100% - 10px);
  min-height: 30px;
  letter-spacing: .5px;
  border: 0;
  background-color: #F7FCFF !important;
  border-bottom: 1px solid #f0f0f0;
}


input:focus, textarea:focus {
  outline: none;
  border-color: #021217;
}

input:valid, textarea:valid {
  border-color: #036d28;
}

input:invalid, textarea:invalid {
  border-color: red;
}


textarea {
  min-height: 120px;
}

small{
  opacity: .666;
  font-size: .5em;
}

.grow-wrap {
  display: grid;
}
.grow-wrap::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}

label {
  display: block;
  margin-top: -10px;
}
.home {
  background: linear-gradient(rgba(0,0,0,.2), rgba(2, 70, 148, 0.644)), url(/static/media/drinking6.312201f9.jpg) no-repeat center center fixed;
  background-size: cover;
  height: calc(100vh - 250px) !important;
}


.kanan-header {
  font-size: calc(10px + 2vmin);
  color: #D0EAF8;
  position: absolute;
  margin: 10px auto;
  bottom: 130px;
}

.kanan-header h1 {
  font-size: 2.5em;
  line-height: 0.8em;
  margin-bottom: -0.3em;
  font-weight: bolder;
}

.drop {
  position: relative;
	width: 20px;
	height: 20px;
  top: -50px;
  margin: 0 auto;
	background: #D0EAF8;
	border-radius: 20px;
  animation-name: drip;
  animation-timing-function: cubic-bezier(1,0,.91,.19);
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.drop:before {
  content: "";
  position: absolute;
  width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 30px solid #D0EAF8;
  top: -22px;
}

@keyframes drip {
    to {
      top: 80vh;
    }
}

